const $ = (css, parent = document) => parent.querySelector(css);
const $$ = (css, parent = document) => parent.querySelectorAll(css);

const UIkit = window.UIkit


import icons from './uikit-icons';
UIkit.use(icons)

import('./uikit-iconslib').then((icons) => {
    icons.default()
})

setTimeout(() => {
    $$('a[href]').forEach(function (a) {
        if (a.href.indexOf('javascript') == 0) return

        try {
            let link = new URL(a.href);
            if (link.host != document.location.host) {
                a.setAttribute('target', '_blank')
                a.setAttribute('rel', 'noopener noreferrer')
            }
        } catch (e) {
            console.error(e)
        }
    })
}, 0)


var mobileMenu = document.getElementById('mobile-menu');
var updateBurgerIcon = (e) => {
    if (e.target != mobileMenu) return;
    var icon = e.type == 'shown' ? 'close' : 'menu';
    document.getElementById('burger-menu').setAttribute('uk-icon', `icon: ${icon}; ratio: 1.5`)
};
mobileMenu.addEventListener('shown', updateBurgerIcon);
mobileMenu.addEventListener('hidden', updateBurgerIcon);

